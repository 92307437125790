import { Reducer } from 'redux';
import { GA_EVENTS, TIME_WINDOW } from '@/constants';
import { FilterState, FilterActionTypes, FilterActions } from '@/store/filter/types';
import { sendEvent } from '@/util/analytics';

export const INITIAL_STATE: FilterState = {
  activeFilter: null,
  timeWindow: {
    preset: TIME_WINDOW.L12.value,
  },
  filters: [],
};

// Reducer
const reducer: Reducer<FilterState, FilterActions> = (
  state = INITIAL_STATE,
  action,
): FilterState => {
  switch (action.type) {
    case FilterActionTypes.SET_PREVIOUS_SESSION:
      if (!action.payload.window_size) {
        return state;
      }

      return {
        ...state,
        timeWindow: { preset: action.payload.window_size },
      };
    case FilterActionTypes.SET_TIME_WINDOW:
      return {
        ...state,
        timeWindow: action.payload,
      };
    case FilterActionTypes.ADD_FILTER:
      sendEvent({
        ...GA_EVENTS.FILTER_CREATE,
        label: action.payload.filter_name,
      });
      return {
        ...state,
        filters: [...state.filters, action.payload],
      };
    case FilterActionTypes.SET_ACTIVE_FILTER:
      if (action.payload)
        sendEvent({
          ...GA_EVENTS.FILTER_SET,
          label: action.payload.filter_name,
        });
      return {
        ...state,
        activeFilter: action.payload,
      };
    case FilterActionTypes.UPDATE_FILTER:
      const idx = state.filters.findIndex(
        (filter) => filter.filter_id === action.payload.filter_id,
      );

      if (idx === -1) {
        return state;
      }
      sendEvent({
        ...GA_EVENTS.FILTER_UPDATE,
        label: action.payload.filter_name,
      });
      return {
        ...state,
        filters: [...state.filters.slice(0, idx), action.payload, ...state.filters.slice(idx + 1)],
      };
    case FilterActionTypes.REMOVE_FILTER:
      return {
        ...state,
        activeFilter: null,
        filters: state.filters.filter((filter) => filter.filter_id !== action.payload.filter_id),
      };
    case FilterActionTypes.SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
