import { createSelector } from 'reselect';
import { ApplicationState } from '@/store/reducers';

const baseSelector = (state: ApplicationState) => state.filter;
export const filterTimeWindowSelector = createSelector(baseSelector, (filter) => filter.timeWindow);
export const activeUserFilterSelector = createSelector(
  baseSelector,
  (filter) => filter.activeFilter,
);
export const userSavedFiltersSelector = createSelector(baseSelector, (filter) => filter.filters);
export const savedFilterNamesSelector = createSelector(baseSelector, (filter) =>
  filter.filters.map((filter) => filter.filter_name),
);
