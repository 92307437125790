import { EventArgs } from 'react-ga';

// URLs
export enum URLS {
  LANDING = '/',
  LOGIN = 'login',
  DASHBOARD = 'dashboard',
}

export interface TimeWindow {
  [key: string]: {
    name: string;
    value: string;
  };
}

export const TIME_WINDOW: TimeWindow = {
  L4: { name: 'Last 4 weeks', value: 'L4' },
  L12: { name: 'Last 12 weeks', value: 'L12' },
  L52: { name: 'Last 52 weeks', value: 'L52' },
  CYTD: { name: 'Calendar YTD', value: 'CYTD' },
  CFYTD: { name: 'Foodbuy YTD', value: 'CFYTD' },
};

export interface GaEvents {
  [key: string]: EventArgs;
}
export enum GaEventCategory {
  INSIGHT = 'Insight',
  FILTER = 'Filter',
  DRILLDOWN = 'Drilldown',
  METRIC = 'Metric',
  USER = 'User',
}
export const GA_EVENTS: GaEvents = {
  INSIGHT_SHOWMORE: { category: GaEventCategory.INSIGHT, action: 'Show More' },
  FILTER_CREATE: { category: GaEventCategory.FILTER, action: 'Create' },
  FILTER_SET: { category: GaEventCategory.FILTER, action: 'Set' },
  FILTER_UPDATE: { category: GaEventCategory.FILTER, action: 'Update' },
  DRILLDOWN_CLICK: { category: GaEventCategory.DRILLDOWN, action: 'Click' },
  DRILLDOWN_EXPORT: { category: GaEventCategory.DRILLDOWN, action: 'Export Data' },
  METRIC_SET_REFERENCE: { category: GaEventCategory.METRIC, action: 'Set Reference' },
  METRIC_SELECT: { category: GaEventCategory.METRIC, action: 'Select' },
  USER_SET_SELECTED_PROFILE: { category: GaEventCategory.USER, action: 'Set Selected Profile' },
};

export const TMP_FILTER_ID = 'unsaved-filter';
