import { ActiveMetricType, MetricItem } from '@/api/user/interface';

export interface MetricState {
  selected: ActiveMetricType[];
  list: MetricItem[];
  loading: boolean;
}

export interface MetricListGroupedType {
  id: string;
  name: string;
  items: MetricItem[];
}

// Actions
export enum MetricActionTypes {
  SET_METRIC_LIST = '@@distilr/metric/SET_METRIC_LIST',
  SET_ACTIVE_METRIC = '@@distilr/metric/SET_ACTIVE_METRIC',
  UNSET_ACTIVE_METRIC = '@@distilr/metric/UNSET_ACTIVE_METRIC',
  SET_SELECTED_METRIC = '@@distilr/metric/SET_SELECTED_METRIC',
  UPDATE_METRIC = '@@distilr/metric/UPDATE_METRIC',
  BULK_SET_METRICS = '@@distilr/metric/BULK_SET_METRICS',
}

export interface SetMetricListAction {
  type: MetricActionTypes.SET_METRIC_LIST;
  payload: MetricItem[];
}

export interface SetActiveMetricAction {
  type: MetricActionTypes.SET_ACTIVE_METRIC;
  payload: ActiveMetricType;
}

export interface UnsetActiveMetricAction {
  type: MetricActionTypes.UNSET_ACTIVE_METRIC;
  payload: string;
}

export interface SetSelectedMetricAction {
  type: MetricActionTypes.SET_SELECTED_METRIC;
  payload: string;
}

export interface UpdateMetricAction {
  type: MetricActionTypes.UPDATE_METRIC;
  payload: {
    id: string;
    updates: {
      [key: string]: string | boolean;
    };
  };
}

export interface BulkSetMetricListAction {
  type: MetricActionTypes.BULK_SET_METRICS;
  payload: ActiveMetricType[];
}

export type MetricActions =
  | SetMetricListAction
  | SetActiveMetricAction
  | UnsetActiveMetricAction
  | SetSelectedMetricAction
  | BulkSetMetricListAction
  | UpdateMetricAction;
