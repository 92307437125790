import { Reducer } from 'redux';
import { ActiveMetricType } from '@/api/user/interface';
import { MetricState, MetricActions, MetricActionTypes } from '@/store/metric/types';
import { SetPreviousSessionType, FilterActionTypes } from '@/store/filter/types';

export const INITIAL_STATE: MetricState = {
  selected: [],
  list: [],
  loading: false,
};

// Reducer
const reducer: Reducer<MetricState, MetricActions | SetPreviousSessionType> = (
  state = INITIAL_STATE,
  action,
): MetricState => {
  switch (action.type) {
    case MetricActionTypes.SET_METRIC_LIST:
      return {
        ...state,
        list: action.payload,
        loading: false,
      };
    case MetricActionTypes.SET_ACTIVE_METRIC:
      return {
        ...state,
        selected: [...state.selected, action.payload],
      };
    case MetricActionTypes.UNSET_ACTIVE_METRIC:
      return {
        ...state,
        selected: handleUnsetMetric(state.selected, action.payload),
      };
    case MetricActionTypes.SET_SELECTED_METRIC:
      return {
        ...state,
        selected: state.selected.map((item) => {
          if (item.selected) {
            item.selected = false;
          }

          if (item.metric_group_id === action.payload) {
            item.selected = true;
          }

          return item;
        }),
      };
    case MetricActionTypes.UPDATE_METRIC:
      return {
        ...state,
        selected: state.selected.map((item) => {
          if (item.metric_group_id === action.payload.id) {
            return {
              ...item,
              ...action.payload.updates,
            };
          }
          return item;
        }),
      };
    case FilterActionTypes.SET_PREVIOUS_SESSION:
      return {
        ...state,
        selected: action.payload.metrics,
      };
    case MetricActionTypes.BULK_SET_METRICS:
      return {
        ...state,
        selected: action.payload,
      };
    default:
      return state;
  }
};

function handleUnsetMetric(selected: ActiveMetricType[], unsetId: string): ActiveMetricType[] {
  const updList = [...selected];
  const unsetIdx = updList.findIndex((i) => i.metric_group_id === unsetId);

  if (unsetIdx === -1) {
    return updList;
  }

  // Check if this item was the selected one
  const isUnsetSelected = updList[unsetIdx].selected;
  updList.splice(unsetIdx, 1);

  // If it was selected, and we still have some items left, set the first to active
  if (updList.length && isUnsetSelected) {
    updList[0].selected = true;
  }

  return updList;
}

export default reducer;
